.hoyee-project-banner {
    width: 40%;
    text-align: center;
    margin: 0 auto;
}
@media screen and (max-width: 768px) {
    .hoyee-project-banner {
        width: 50%;
    }
}
