.intense-list ul li {
    margin-top: 0.5rem;
}

.intense-list ul {
    list-style-position: outside;
    padding-left: 20px;
}

table td {
    font-weight: normal; /* Default font weight for all table cells */
    color: #666; /* Lighter font color for the second column */
}

.question-block {
    padding-top: 24px;
}

.first-column {
    width: 400px;
    font-weight: bold;
    color: #333;
}
@media screen and (max-width: 768px) {
    .first-column {
        width: 191px;
    }
    .responsive-table tr {
        display: flex;
        flex-direction: column;
    }
}
