.skeleton-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px; /* Expanded vertical size */
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 8px; /* Rounded corners */
    margin: 20px 0; /* Spacing */
    font-size: 1.5rem; /* Text size */
    color: #999; /* Text color */
    position: relative;
    overflow: hidden;
}

.skeleton-animation::before {
    content: "Loading...";
    position: absolute;
    z-index: 1;
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}