.appraisal-bottom-box {
    display: flex;
    padding-right: 10%;
    padding-left: 10%;
}
.appraisal-bottom-box-left-bg {
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: white;
    padding: 100px;
    text-align: left;
    width: 50%;
}
@media screen and (max-width: 768px) {
    .appraisal-bottom-box {
        display: block;
        padding-right: 0;
        padding-left: 0;
    }
    .appraisal-bottom-box-left-bg {
        display: none;
    }
}
