.search-from-list-page-customize .nice-select {
    height: 40px !important;
    border: none !important;
    background: none !important;
    -webkit-appearance: none; /* For Safari and Chrome */
    appearance: none; /* For modern browsers */
    display: flex;
    align-items: center;
    /*border-bottom: 1px solid #ccc !important; !* Add a bottom border line *!*/
}
