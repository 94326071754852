.alert {
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    background-color: #4CAF50;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 16px;
    animation: slideDown 0.5s ease-out, fadeOut 3s 2.5s ease-in;
}

.alert-error {
    background-color: #ff0033;
}

@keyframes slideDown {
    from {
        top: -50px;
    }
    to {
        top: 20px;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
