.ltn__author-widget-inner img {
    border-radius: 0 !important;
}

.inspection-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

.inspection-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inspection-card__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 80px;
}

.inspection-card__day {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.inspection-card__date-number {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
}

.inspection-card__month {
    font-size: 14px;
    text-transform: uppercase;
}

.inspection-card__time {
    font-size: 14px;
    text-align: right;
}

@media (max-width: 767px) {
    .inspection-card {
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    .inspection-card__date {
        flex-direction: column;
        align-items: flex-start;
    }

    .inspection-card__time {
        text-align: right;
        flex-grow: 1;
    }
    
    .ltn__inspection-widget {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mobile-inspection-widget .ltn__inspection-widget {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mobile-inspection-widget .inspection-cards {
        margin-bottom: 0;
    }

    .mobile-inspection-widget .inspection-card {
        background-color: #f7f7f7 !important; /* Changed to match card style
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
        border-radius: 8px; /* Added border radius */
        padding: 15px; /* Adjusted padding */
        border-bottom: none; /* Removed border bottom */
        margin-left: 15px;
        margin-right: 15px;
    }

    .mobile-inspection-widget .inspection-card:last-child {
        border-bottom: none;
    }
}
